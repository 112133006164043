import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const ChevronDouble: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8 16.8L6 12L10.8 7.19995M18 16.8L13.2 12L18 7.19995"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronDouble;
