import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const serverURL =
  window.ENV[process.env.NODE_ENV || 'development'].apiOperationsUrl;

const operationsAPI = axios.create({
  timeout: 600000,
  baseURL: serverURL,
});

operationsAPI.interceptors.request.use(defaultAPIRequestMiddleware, (error) =>
  Promise.reject(error)
);

operationsAPI.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, operationsAPI)
);

export default operationsAPI;
