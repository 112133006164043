import { useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { round } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import type { DateValueType } from 'react-tailwindcss-datepicker';

import { balance } from '@/assets/images/dashboard';
import { Button } from '@/components/global';
import LoadingSpinner from '@/components/global/LoadingSpinner';
import RSelect from '@/components/global/RSelect';
import { useAppFiscalYear, useAppTranslation } from '@/hooks';
import OperationsService from '@/services/OperationsService';
import type { CategoryBalanceResponse } from '@/services/models';
import { CsvToExcelConverter, exportToCsv, formatBalance } from '@/utils/funcs';

import { NoData } from '../../common';
import type { Category } from '../AccountingPlan/AccountingPlan';
import { Tabs } from '../common';

const columns = [
  'CATEGORY_CODE',
  'LABEL',
  'REPORT',
  'CREDIT',
  'DEBIT',
  'BALANCE',
];

const groupingCodes = ['411', '425', '401'];

type GroupedCategories = {
  [key: string]: { data: CategoryBalanceResponse[]; composite: boolean };
};

type Filters = {
  query: string;
};

const CategoryBalance = () => {
  const { t } = useAppTranslation();

  const fiscalYear: number = useAppFiscalYear();

  const [filters, setFilters] = useState<Filters>({ query: '' });

  const [selectedDate, setSelectedDate] = useState<any>({
    startDate: new Date(new Date().getFullYear(), 11, 31),
    endDate: new Date(new Date().getFullYear(), 11, 31),
  });

  const handleDateChange = (newDate: DateValueType) => {
    setSelectedDate(newDate);
  };

  const { data: categoriesBalanceData, isLoading } = useQuery<
    CategoryBalanceResponse[]
  >({
    queryKey: ['categoryBalance', fiscalYear, selectedDate],
    queryFn: async () => {
      const endDate = selectedDate?.endDate
        ? moment(selectedDate.endDate).format('YYYY/MM/DD')
        : moment(new Date(new Date().getFullYear(), 11, 31)).format(
            'YYYY/MM/DD'
          );
      const startDate = selectedDate?.startDate
        ? moment(selectedDate.startDate).format('YYYY/MM/DD')
        : moment(new Date(new Date().getFullYear(), 1, 1)).format('YYYY/MM/DD');
      const res = await OperationsService.getCategoryBalance(
        fiscalYear,
        startDate,
        endDate
      );
      return res.data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const categoriesBalance = useMemo(() => {
    const data = categoriesBalanceData?.map((el) => {
      const accountingPlan = JSON.parse(
        localStorage.getItem('accountingPlan') ?? '{}'
      ) as Category[];
      return {
        ...el,
        debit: round(el.debit, 3),
        credit: round(el.credit, 3),
        postponed: round(el.postponed, 3),
        balance: round(el.balance, 3),
        label:
          parseInt(el.categoryCode) in accountingPlan
            ? accountingPlan[parseInt(el.categoryCode)].label
            : '',
      };
    });

    return data;
  }, [categoriesBalanceData]);

  const filteredCategoryBalance = useMemo(() => {
    if (filters.query !== '') {
      return categoriesBalance?.filter((el) =>
        el?.categoryCode?.startsWith(filters.query)
      );
    }

    return categoriesBalance;
  }, [categoriesBalance, filters]);

  const groupedCategories = useMemo(() => {
    return (filteredCategoryBalance || []).reduce((acc, curr) => {
      const baseCode = groupingCodes.find((code) =>
        curr?.categoryCode?.toString().startsWith(code)
      );

      if (baseCode) {
        if (!acc[baseCode]) {
          acc[baseCode] = { data: [], composite: true };
        }

        acc[baseCode].data.push(curr);
      } else {
        acc[curr.categoryCode] = { data: [curr], composite: false };
      }

      return acc;
    }, {} as GroupedCategories);
  }, [filteredCategoryBalance]);

  return (
    <section className="p-6 flex flex-col gap-4 w-full h-screen bg-white">
      <div className="grid grid-cols-12 gap-4 w-full mb-6">
        <Tabs selected="CATEGORY_BALANCE" />
      </div>
      <div className="col-span-12 flex justify-between">
        <h2 className="text-2xl font-semibold mb-6">{t('CATEGORY_BALANCE')}</h2>
        <div className="flex gap-2">
          <div className="flex items-center gap-4 z-50 ">
            <Datepicker
              inputId="datepicker"
              inputClassName="h-12 px-4 py-2 rounded-xl border-2 border-solid border-[#D2D1D6] focus:border-none focus:outline-none"
              value={selectedDate}
              displayFormat={'DD/MM/YYYY'}
              onChange={handleDateChange}
              //useRange={false}
              dateLooking="middle"
              showShortcuts={true}
              configs={{
                shortcuts: {
                  last3Days: {
                    text: t('The_previous_month'),
                    period: {
                      start: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() - 1,
                        1
                      ),
                      end: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        0
                      ),
                    },
                  },
                  customToday: {
                    text: t('Current_month'),
                    period: {
                      start: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      ),
                      end: new Date(),
                    },
                  },
                  beginningYear: {
                    text: t('Current_Year'),
                    period: {
                      start: new Date(new Date().getFullYear(), 0, 1),
                      end: new Date(),
                    },
                  },
                },
                footer: {
                  cancel: 'CText',
                  apply: 'AText',
                },
              }}
            />
          </div>
          <div className="flex items-center gap-4">
            <SearchBox
              onFilterChange={(val: Filters) =>
                setFilters((filters) => ({ ...filters, ...val }))
              }
            />
            <BalanceExport data={categoriesBalance} />
          </div>
        </div>
      </div>
      <div className="col-span-12 py-3 rounded-3xl bg-first w-full min-h-[55vh] overflow-y-auto">
        <div className="flex justify-end items-center py-3 gap-4">
          {isLoading && (
            <div className="w-full h-full absolute bg-white opacity-50 cursor-no-drop z-10 flex items-center justify-center">
              <LoadingSpinner
                width="35"
                strokeWidth="4"
                strokeColor="#5d5ff8"
              />
            </div>
          )}
        </div>
        {filteredCategoryBalance && filteredCategoryBalance.length > 0 ? (
          <CategoryBalanceTable data={groupedCategories} />
        ) : (
          <NoData
            isFullpage={false}
            illustration={balance}
            width={155}
            height={155}
            title={t('NO_DATA_FOUND')}
            description={t('No data found in your search on this page')}
          />
        )}
      </div>
    </section>
  );
};

const BalanceExport = ({
  data,
}: {
  data: CategoryBalanceResponse[] | undefined;
}) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!data) return <></>;

  const handleExport = (format: 'csv' | 'xls' = 'csv') => {
    const header = [
      t('CATEGORY_CODE'),
      t('LABEL'),
      t('REPORT'),
      t('CREDIT'),
      t('DEBIT'),
      t('BALANCE'),
    ];
    const headerKeys = [
      'categoryCode',
      'label',
      'postponed',
      'credit',
      'debit',
      'balance',
    ];
    const content = exportToCsv(data, headerKeys, header);

    if (format === 'xls') {
      const excelBuffer = CsvToExcelConverter(content);
      download(excelBuffer, 'category-balance.xls', 'application/vnd.ms-excel');
    } else {
      download(content, 'category-balance.csv', 'text/csv;charset=utf-16');
    }
  };

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={t('EXPORT')}
      />
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleExport()}>{t('EXPORT_CSV')}</MenuItem>
        <MenuItem onClick={() => handleExport('xls')}>
          {t('EXPORT_XLS')}
        </MenuItem>
      </Menu>
    </>
  );
};

const CategoryBalanceTable = ({ data }: { data: GroupedCategories }) => {
  const { t } = useAppTranslation();

  return (
    <div className="accountant">
      <table className="w-full text-left text-gray-500 relative">
        <thead className="text-gray-700 border-b">
          <tr>
            {/* En-tête du tableau */}
            {columns?.map((column, idx) => (
              <th
                scope="col"
                className={`${
                  column == 'REPORT' ||
                  column == 'CREDIT' ||
                  column == 'DEBIT' ||
                  column == 'BALANCE'
                    ? 'text-right'
                    : 'text-left'
                } px-6 py-3 text-center !text-xs !font-bold text-[#8D98AF] w-[800px]`}
                key={idx}
              >
                {t(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((category, index) => {
            if (data[category].composite && data[category].data.length > 1) {
              return (
                <CompositeTableLine
                  key={index}
                  data={data[category].data}
                  baseCode={category}
                />
              );
            }
            return data[category].data.map((line) => (
              <TableLine key={index} data={line} />
            ));
          })}
        </tbody>
      </table>
    </div>
  );
};

const keys = [
  'categoryCode',
  'label',
  'postponed',
  'credit',
  'debit',
  'balance',
];

const TableLine = ({
  data,
  secondary = false,
}: {
  data: CategoryBalanceResponse;
  secondary?: boolean;
}) => {
  return (
    <tr
      className={
        '!text-xs !font-bold px-3 border-bottom' +
        (secondary ? 'bg-[#f1f1f1]' : '')
      }
    >
      {keys.map((key, index) => (
        <td
          key={index}
          className={`px-6 py-3 ${secondary && '!bg-[#e7e6e6]'} ${
            key === 'label'
              ? 'text-left w-[10%]'
              : key === 'categoryCode'
              ? 'text-left w-[5%]'
              : `text-right w-[10%]`
          }`}
        >
          {key == 'credit' ||
          key == 'debit' ||
          key == 'debitBalance' ||
          key == 'creditBalance' ||
          key == 'balance' ||
          key == 'postponed'
            ? formatBalance(data[key as keyof CategoryBalanceResponse])
            : key == 'categoryCode'
            ? String(data[key as keyof CategoryBalanceResponse])?.padEnd(6, '0')
            : data[key as keyof CategoryBalanceResponse]}
        </td>
      ))}
    </tr>
  );
};

const CompositeTableLine = ({
  data,
  baseCode,
}: {
  data: CategoryBalanceResponse[];
  baseCode: string;
}) => {
  const [showBreakdown, setShowBreakdown] = useState(false);
  const mainLine: CategoryBalanceResponse = useMemo(() => {
    const accountingPlan = JSON.parse(
      localStorage.getItem('accountingPlan') ?? '{}'
    ) as Category[];
    const label =
      parseInt(baseCode) in accountingPlan
        ? accountingPlan[parseInt(baseCode)].label
        : '';
    return data.reduce(
      (acc, curr) => {
        return {
          ...acc,
          label,
          postponed: acc.postponed + curr.postponed,
          credit: acc.credit + curr.credit,
          debit: acc.debit + curr.debit,
          balance: acc.balance + curr.balance,
        };
      },
      {
        categoryCode: baseCode,
        label: '',
        postponed: 0,
        credit: 0,
        debit: 0,
        balance: 0,
      }
    );
  }, [data]);

  return (
    <>
      <tr className="!text-xs !font-bold px-3 border-bottom">
        {keys.map((key, index) =>
          index === 0 ? (
            <td
              key={index}
              className={`relative px-6 py-3 w-[10%] ${
                key === 'categoryCode' && 'text-left w-[5%] pl-12'
              }`}
            >
              <ExpandMoreIcon
                className={
                  'absolute left-[80px] top-[calc(50%-12px)] hover:bg-[#ebebeb] rounded-full hover:transition-all duration-300' +
                  (showBreakdown ? ' rotate-180' : '')
                }
                onClick={() =>
                  setShowBreakdown((showBreakdown) => !showBreakdown)
                }
              />
              {String(mainLine[key as keyof CategoryBalanceResponse]).padEnd(
                6,
                '0'
              )}
            </td>
          ) : (
            <td
              key={index}
              className={`relative px-6 py-3 w-[10%] ${
                key === 'label' ? 'text-left' : `text-right`
              }`}
            >
              {key !== 'label'
                ? formatBalance(mainLine[key as keyof CategoryBalanceResponse])
                : mainLine[key as keyof CategoryBalanceResponse]}
            </td>
          )
        )}
      </tr>

      {showBreakdown &&
        data.map((line, index) => (
          <TableLine key={index} data={line} secondary />
        ))}
    </>
  );
};

interface CodeForm {
  code: string;
}

const SearchBox = ({ onFilterChange }: any) => {
  const categoriesList = useMemo(
    () =>
      JSON.parse(localStorage.getItem('accountingPlan') ?? '{}') as Category[],
    []
  );
  const { register, control } = useForm<CodeForm>({
    defaultValues: {
      code: '',
    },
    mode: 'all',
  });

  const debounceSearchQueryChange = () => {
    /*debounce(() => onFilterChange({ query: val }), 1000);
        onFilterChange({ query: val })
        console.log(val);*/
  };

  return (
    <RSelect
      id={'categoryCode'}
      refs={register('code')}
      isSearchable
      onSelect={(val) => {
        onFilterChange({ query: val.value });
      }}
      onInputChange={() => debounceSearchQueryChange()}
      labelCls=" text-md"
      options={[
        { label: 'Select...', value: '' },
        ...(Object.values(categoriesList)?.map((el) => {
          return {
            label: `${el.code} - ${el.label}`,
            value: el.code,
          };
        }) || []),
      ]}
      control={control}
      styles={{
        control: (provided) => ({
          ...provided,
          height: '48px',
          borderRadius: '8px',
          backgroundColor: 'transparent',
          width: '315px',

          '&:hover': {
            backgroundColor: '#e5e7eb',
          },
          '&:focus': {
            backgroundColor: '#fff',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#7B7B8D ', // set the color to reddd
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#e5e7eb' : 'transparent',
          color: '#111827',
          '&:hover': {
            backgroundColor: '#e5e7eb',
          },
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: '8px',
          boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
          backgroundColor: '#fff',
        }),
      }}
    />
  );
};

export default CategoryBalance;
