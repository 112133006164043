import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const ArrowUpSquare: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75729 11.3344L12 7.1999M12 7.1999L16.2426 11.3344M12 7.1999V16.2842M18 21.5999L5.9999 21.5999C4.01168 21.5999 2.3999 19.9881 2.3999 17.9999L2.3999 5.9999C2.3999 4.01168 4.01168 2.3999 5.9999 2.3999L18 2.3999C19.9882 2.3999 21.6 4.01168 21.6 5.9999V17.9999C21.6 19.9881 19.9882 21.5999 18 21.5999Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowUpSquare;
