import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import useWebSocket from 'react-use-websocket';

import {
  getInitialTheme,
  useAppDispatch,
  useAppSelector,
  useBrowserFingerprint,
} from './hooks';
import { SuspenseFallback } from './pages/other';
import Router from './router';
import { CompanyService, SaasService } from './services';
import {
  setCompany,
  setCompanySubscription,
  setConnectedUser,
  setIsPreloadState,
  setNotifications,
} from './store/actions/action-creators';

const SOCKET_URL =
  window.ENV[process.env.NODE_ENV || 'staging'].wsNotificationUrl;

const App: FC = () => {
  const isPreloadState = useAppSelector((store) => store.global.isPreloadState);
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const dispatch = useAppDispatch();
  const isDoneFingerprinting = useBrowserFingerprint();

  const webSocketUrl = useMemo(
    () =>
      connectedUser?.id && connectedUser?.companyId
        ? `${SOCKET_URL}/${connectedUser.id}/${connectedUser.companyId}`
        : null,
    [connectedUser]
  );

  const { lastMessage } = useWebSocket(webSocketUrl);

  const notifications = useAppSelector((store) => store.global.notifications);

  const authCheckInterval = 5 * 60 * 1000;

  useEffect(() => {
    if (!isDoneFingerprinting) return;
    getInitialTheme();
    const accessToken = localStorage.getItem('access-token');
    if (!accessToken) {
      dispatch(setIsPreloadState(false));
      return;
    }
    const controller = new AbortController();

    const fetchUserDetails = async () => {
      const connectedUserRes: any = await SaasService.getConnectedUser(
        controller.signal
      );
      dispatch(setConnectedUser(connectedUserRes.data));
    };

    const fetchCompanySubscription = async () => {
      const companySub: any = await CompanyService.getCompanySubscription(
        controller.signal
      );
      dispatch(setCompanySubscription(companySub.data));
    };

    CompanyService.getCompanyInfos(controller.signal).then((res) =>
      dispatch(setCompany(res.data))
    );

    fetchUserDetails()
      .then(async () => {
        await fetchCompanySubscription()
          .then(() => dispatch(setIsPreloadState(false)))
          .catch(() => dispatch(setIsPreloadState(false)));
      })
      .catch(() => {
        localStorage.removeItem('access-token');
        dispatch(setIsPreloadState(false));
      });

    return () => controller.abort();
  }, [isDoneFingerprinting]);

  useEffect(() => {
    const controller = new AbortController();
    setInterval(async () => {
      if (window.location.pathname == '/auth/sign-in') return;
      await SaasService.getConnectedUser(controller.signal);
    }, authCheckInterval);
    return () => {
      controller.abort();
      clearInterval(authCheckInterval); // Clear the interval on component unmount
    };
  }, []);

  useEffect(() => {
    if (lastMessage) {
      const notification = JSON.parse(lastMessage.data);
      dispatch(setNotifications([...notifications, notification]));
    }
  }, [lastMessage]);

  if (isPreloadState) return <SuspenseFallback />;

  return <Router />;
};

export default App;
