import type { FC } from 'react';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Backdrop } from '@mui/material';

interface SuspenseFallbackProps {}

const SuspenseFallback: FC<SuspenseFallbackProps> = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <DotLottieReact
        src="https://lottie.host/863f9fbb-e841-4f15-a155-bf23b6d6f174/1RCfJ0q2AT.lottie"
        loop
        autoplay
        className="w-16	h-16"
      />
    </Backdrop>
  );
};

export default SuspenseFallback;
