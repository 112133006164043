import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const ArrowDownSquare: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2431 12.6654L12.0004 16.7999M12.0004 16.7999L7.75777 12.6654M12.0004 16.7999L12.0004 7.71564M6.00039 2.3999L18.0005 2.3999C19.9887 2.3999 21.6005 4.01168 21.6005 5.9999L21.6005 17.9999C21.6005 19.9881 19.9887 21.5999 18.0005 21.5999L6.00039 21.5999C4.01216 21.5999 2.40039 19.9881 2.40039 17.9999L2.40039 5.9999C2.40039 4.01168 4.01217 2.3999 6.00039 2.3999Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowDownSquare;
