import * as yup from 'yup';

import declarationConfigTemplate from './config-template.json';

export enum FieldType {
  CHECKBOX = 'CHECKBOX',
  STRING = 'STRING',
  CATEGORY_CODES = 'CATEGORY_CODES',
}

export interface SectionType {
  name: string;
  isActive: boolean;
  active: boolean;
}

export interface SectionTabsType {
  name: string;
  href: string;
  permission: string;
}

export interface DeclarationFormType {
  id?: string;
  fiscalYear: number;
  tenantId: string;
  sections: SectionType[];
  fields: {
    [key: string]: {
      value: boolean | string | string[] | null;
      type: FieldType;
      base?: boolean;
      section?: string;
    };
  };
  validated: boolean;
  month?: number;
  declaredBy?: string;
  amount?: number;
}

export interface DeclarationListType {
  id: string;
  fiscalYear: number;
  tenantId: string;
  declarationDate: string;
  amount: number;
  declaredBy: string;
  month: number;
}

export const WithholdingFormSchema = yup.object().shape({
  A1: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A2: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A3: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A4: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A5: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A6: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A7: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A8: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A9: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A10: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A11: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A12: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A13: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A14: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A15: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A16: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A17: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A18: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A19: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A20: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A21: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A22: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A23: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A24: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A25: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A26: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A27: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A28: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A29: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A30: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A31: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A32: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A33: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A34: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A35: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A36: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A37: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A38: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A39: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A40: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A41: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A42: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A43: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A44: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A45: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A46: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A47: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A48: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A49: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A50: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A51: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A52: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A53: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A54: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A55: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A56: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A57: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A58: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A59: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
  A60: yup.object().shape({
    value: yup.array().of(yup.string()),
    type: yup.string(),
  }),
});

export const declarationTemplateForm: DeclarationFormType =
  declarationConfigTemplate as DeclarationFormType;

//! Keep it just in case
/*xport const declarationTemplateForm: DeclarationFormType = {
  fiscalYear: 2024,
  tenantId: '',
  sections: [],
  fields: {
    //! A: Withholding tax
    A1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A8: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A9: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A10: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A11: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A12: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A13: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A14: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A15: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A16: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A17: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A18: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A19: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A20: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A21: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A22: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A23: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A24: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A25: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A26: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A27: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A28: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A29: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A30: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A31: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A32: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A33: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A34: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A35: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A36: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A37: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A38: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A39: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A40: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A41: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A42: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A43: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A44: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A45: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A46: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A47: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A48: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A49: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A50: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A51: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A52: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A53: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A54: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A55: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A56: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A57: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A58: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    A59: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'WITHHOLDING_TAX',
    },
    A60: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'WITHHOLDING_TAX',
    },
    //! B: Professional Training Tax
    B1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    B7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'PROFESSIONAL_TRAINING_TAX',
    },
    //! C: FOPROLOS
    C1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'FOPROLOS',
    },
    C2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'FOPROLOS',
    },
    //! D: Consumer Rights
    D1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D2: { value: null, type: FieldType.STRING, base: true, section: '' },
    D3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'CONSUMER_RIGHTS',
    },
    D4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D6: { value: null, type: FieldType.STRING, base: true, section: '' },
    D7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D8: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'CONSUMER_RIGHTS',
    },
    D9: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D10: { value: null, type: FieldType.STRING, base: true, section: '' },
    D11: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'CONSUMER_RIGHTS',
    },
    D12: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'CONSUMER_RIGHTS',
    },
    //! E: VAT
    E1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E8: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E9: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E10: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E11: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E12: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E13: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E14: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E15: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E16: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E17: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E18: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E19: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E20: { value: null, type: FieldType.STRING, base: true, section: '' },
    E21: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E22: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E23: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E24: { value: null, type: FieldType.STRING, base: true, section: '' },
    E25: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E26: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E27: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E28: { value: null, type: FieldType.STRING, base: true, section: '' },
    E29: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E30: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E31: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E32: { value: null, type: FieldType.STRING, base: true, section: '' },
    E33: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E34: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E35: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E36: { value: null, type: FieldType.STRING, base: true, section: '' },
    E37: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E38: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E39: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E40: { value: null, type: FieldType.STRING, base: true, section: '' },
    E41: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E42: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E43: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E44: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E45: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E46: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E47: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E48: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E49: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E50: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E51: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E52: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E53: { value: null, type: FieldType.STRING, base: true, section: '' },
    E54: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E55: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E56: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E57: { value: null, type: FieldType.STRING, base: true, section: '' },
    E58: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E59: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E60: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E61: { value: null, type: FieldType.STRING, base: true, section: '' },
    E62: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E63: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E64: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'VAT',
    },
    E65: { value: null, type: FieldType.STRING, base: true, section: '' },
    E66: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    E67: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'VAT',
    },
    //! F: Other Taxes
    F1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F8: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F9: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F10: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F11: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F12: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F13: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F14: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F15: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F16: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F17: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F18: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F19: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F20: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F21: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F22: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F23: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F24: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F25: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F26: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F27: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F28: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F29: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F30: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F31: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F32: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    F33: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'OTHER_TAXES',
    },
    F34: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'OTHER_TAXES',
    },
    //! G: Insurance taxes
    G1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G7: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G8: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G9: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G10: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G11: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G12: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G13: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G14: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G15: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G16: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G17: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G18: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G19: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G20: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G21: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G22: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G23: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G24: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G25: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G26: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G27: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G28: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G29: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G30: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'INSURANCE_TAXES',
    },
    G31: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G32: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G33: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G34: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G35: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G36: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    G37: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'INSURANCE_TAXES',
    },
    //! H: Stamp Duty
    H1: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'STAMP_DUTY',
    },
    H2: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'STAMP_DUTY',
    },
    H3: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'STAMP_DUTY',
    },
    H4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'STAMP_DUTY',
    },
    //! I: Local Taxes
    I1: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I2: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I3: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I4: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I5: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I6: {
      value: null,
      type: FieldType.CATEGORY_CODES,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I7: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I8: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I9: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I10: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I11: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I12: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I13: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I14: {
      value: null,
      type: FieldType.STRING,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I15: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I16: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I17: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I18: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I19: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I20: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I21: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I22: {
      value: null,
      type: FieldType.STRING,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I23: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I24: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I25: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I26: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I27: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I28: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I29: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I30: {
      value: null,
      type: FieldType.STRING,
      base: false,
      section: 'LOCAL_TAXES',
    },
    I31: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I32: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I33: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I34: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I35: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I36: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I37: {
      value: null,
      type: FieldType.STRING,
      base: true,
      section: 'LOCAL_TAXES',
    },
    I38: {
      value: null,
      type: FieldType.STRING,
      base: false,
      section: 'LOCAL_TAXES',
    },
  },
  validated: false,
};*/
