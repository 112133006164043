import React from 'react';

import Menu from '@mui/material/Menu';

import { AlertTriangle } from '@/assets/icons';

interface ExpiredPlanButtonProps {
  buttonLabel: string;
  heading: string;
  description: string;
}

function ExpiredPlanButton({
  buttonLabel,
  heading,
  description,
}: ExpiredPlanButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <button
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
        className="bg-[#FBF2EA] text-[#D17C2B] text-xs font-regular flex gap-2 items-center justify-center h-8 py-2.5 px-4 rounded-[8px]"
      >
        <span>
          <AlertTriangle width={16} height={16} strokeColor="#D17C2B" />
        </span>
        {buttonLabel}
      </button>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        sx={{
          marginTop: '10px',
          borderRadius: '12px',
        }}
      >
        <div className="p-4">
          <div className="flex items-center gap-4">
            <span>
              <AlertTriangle width={24} height={24} strokeColor="#D17C2B" />
            </span>
            <p className="text-[#D17C2B] font-semibold">{heading}</p>
          </div>
          <p className="opacity-80 pt-2 pb-2 color-black max-w-[300px]">
            {description}
          </p>
        </div>
      </Menu>
    </div>
  );
}

export default ExpiredPlanButton;
