import type { ComponentProps, FC, ReactNode } from 'react';

import { getCls } from '../helpers';

const DEFAULT_CONTAINER_CLS = 'flex flex-col gap-2 w-fit';
const DEFAULT_LABEL_CLS = 'block';
const DEFAULT_CLS = 'ml-1';
const DEFAULT_ERROR_MSG_CLS = 'text-sm font-semibold text-red-400';

interface CheckboxProps extends ComponentProps<'input'> {
  /**
   * @default ''
   */
  containerCls?: string;

  /**
   * @default ''
   */
  cls?: string;

  // errorCls?: string;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default ''
   */
  errorMsgCls?: string;

  /**
   * @default ''
   */
  label?: string | ReactNode /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  errorMsg?: string /* | DefaultTFuncReturn */;

  /**
   * @default true
   */
  applyDefaultContainerCls?: boolean;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorMsgCls?: boolean;

  /**
   * @default null
   */
  refs?: any;
}

/**
 * Global Checkbox Component
 * @param {string} label label text
 * @param {string} errorMsg error message text
 * @param {string} containerCls container class
 * @param {string} cls checkbox class
 * @param {string} labelCls label class
 * @param {string} errorMsgCls error message class
 * @param {boolean} applyDefaultContainerCls whether to apply the default container class
 * @param {boolean} applyDefaultCls whether to apply the default checkbox class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {boolean} applyDefaultErrorMsgCls whether to apply the default error message class
 * @param {any[]} refs react-hook-form's register function references
 * @param {any[]} ...props list of props to pass to the native checkbox element
 * @example <Checkbox id="agreeToTerms" label="I agree to the terms and conditions." errorMsg={errors.agreeToTerms && 'Please agree to the terms.'} refs={register('agreeToTerms')} />
 */
const Checkbox: FC<CheckboxProps> = ({
  containerCls = '',
  cls = '',
  // errorCls = '',
  labelCls = '',
  errorMsgCls = '',
  label = '',
  errorMsg = '',
  applyDefaultContainerCls = true,
  applyDefaultCls = true,
  applyDefaultLabelCls = true,
  applyDefaultErrorMsgCls = true,
  refs = null,
  ...props
}) => {
  containerCls = getCls(
    applyDefaultContainerCls,
    DEFAULT_CONTAINER_CLS,
    containerCls
  );

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);

  errorMsgCls = getCls(
    applyDefaultErrorMsgCls,
    DEFAULT_ERROR_MSG_CLS,
    errorMsgCls
  );

  return (
    <div className={containerCls}>
      <div className="flex gap-2 items-center">
        <input type="checkbox" {...refs} {...props} className={cls} />
        {label && (
          <label htmlFor={props.id} className={labelCls}>
            {label}
          </label>
        )}
      </div>

      {errorMsg && <p className={errorMsgCls}>{errorMsg}</p>}
    </div>
  );
};

export default Checkbox;
