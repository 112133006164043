import { useState } from 'react';
import type { ComponentProps, FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { EyeClose, EyeOpen } from '@/assets/icons';

import type { IconPosition, InputSize } from '..';
import { getCls, getIconSuitableCls } from '..';

const DEFAULT_CONTAINER_CLS = 'text-left flex flex-col gap-1 w-full';

const DEFAULT_LABEL_CLS = 'block text-sm text-[#30313D] font-normal';
const DEFAULT_ERROR_CLS_MEDIUM =
  'h-[28px] text-sm px-4 py-2 rounded-[8px] border border-solid border-red-400 bg-red-100 text-slate-900 placeholder-red-300 w-full';

const DEFAULT_ERROR_CLS_BIG =
  'h-[40px] text-sm px-4 py-2 rounded-[8px] border border-solid border-red-400 bg-red-100 text-slate-900 placeholder-red-300 w-full';

const DEFAULT_ERROR_MSG_CLS = 'text-xs text-red-400';

const DEFAULT_ICON_CONTAINER_CLS_MEDIUM = 'absolute top-[2px]';

const DEFAULT_ICON_CONTAINER_CLS_BIG = 'absolute top-[10px]';

const DEFAULT_ICON_CONTAINER_CLS_SEC_INPUT = 'absolute top-[8px]';

const DEFAULT_CLS_MEDIUM =
  'h-[28px] text-sm font-normal px-4 py-3 rounded-[8px] border border-solid w-full border-[#C8CFD5] disabled:opacity-50 disabled:bg-[#e7e7e7] disabled:border-[#c7c7c7]';

const DEFAULT_CLS_BIG =
  'h-[40px] text-sm font-normal px-4 py-3 rounded-[8px] border border-solid w-full border-[#C8CFD5] disabled:opacity-50 disabled:bg-[#e7e7e7] disabled:border-[#c7c7c7]';

interface InputProps extends ComponentProps<'input'> {
  /**
   * @default ''
   */
  containerCls?: string;
  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  errorCls?: string;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default ''
   */
  errorMsgCls?: string;

  /**
   * @default ''
   */
  iconContainerCls?: string;

  /**
   * @default ''
   */
  label?: string /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  errorMsg?: string /* | DefaultTFuncReturn */;

  /**
   * @default false
   */

  hasError?: boolean;

  /**
   * @default true
   */
  applyDefaultContainerCls?: boolean;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default true
   */
  applyDefaultErrorMsgCls?: boolean;

  /**
   * @default true
   */
  applyDefaultIconContainerCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default null
   */
  refs?: any;

  /**
   * @default false
   */
  isSecInputIon?: boolean;

  /**
   * @default 'medium''
   */
  inputSize?: InputSize;
}

/**
 * Global Input Component
 * @param {string} label label text
 * @param {string} errorMsg error message text
 * @param {string} containerCls container class
 * @param {string} cls input class
 * @param {string} errorCls input error class
 * @param {string} iconContainerCls input's icon container class
 * @param {string} labelCls label class
 * @param {boolean} hasError whether the input has an error
 * @param {boolean} applyDefaultContainerCls whether to apply the default container class
 * @param {boolean} applyDefaultCls whether to apply the default input class
 * @param {boolean} applyDefaultErrorCls whether to apply the default input error class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {boolean} applyDefaultErrorMsgCls whether to apply the default error message class
 * @param {boolean} applyDefaultIconContainerCls whether to apply the default input's icon container class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {boolean} isSecInputIon change icon input position
 * @param {any[]} refs react-hook-form's register function references
 * @param {any[]} ...props list of props to pass to the native input element
 * @example <Input id="email" label="Email:" placeholder="Enter you email here.." icon={<EmailIcon />} errorMsg={errors.email && 'Please enter a valid email.'} refs={register('email')} />
 */

const Input: FC<InputProps> = ({
  containerCls = '',
  labelCls = '',
  errorCls = '',
  errorMsgCls = '',
  iconContainerCls = '',
  label = '',
  errorMsg = '',
  hasError = false,
  applyDefaultContainerCls = true,
  applyDefaultErrorCls = true,
  applyDefaultLabelCls = true,
  applyDefaultErrorMsgCls = true,
  applyDefaultIconContainerCls = true,
  icon = null,
  iconPosition = 'left',
  refs = null,
  isSecInputIon = false,
  inputSize = 'big',
  type = 'text',
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const inputType =
    type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type;

  hasError = hasError || !!errorMsg;

  containerCls = getCls(
    applyDefaultContainerCls,
    DEFAULT_CONTAINER_CLS,
    containerCls
  );

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  errorMsgCls = getCls(
    applyDefaultErrorMsgCls,
    DEFAULT_ERROR_MSG_CLS,
    errorMsgCls
  );

  const getInputSizeCLS = (size: InputSize) => {
    const sizeCLS = {
      big: DEFAULT_CLS_BIG,
      medium: DEFAULT_CLS_MEDIUM,
    };
    return sizeCLS[size];
  };

  const getInputIconSizeCLS = (size: InputSize) => {
    const sizeCLS = {
      big: DEFAULT_ICON_CONTAINER_CLS_BIG,
      medium: DEFAULT_ICON_CONTAINER_CLS_MEDIUM,
    };
    return sizeCLS[size];
  };

  const getInputErrorSizeCLS = (size: InputSize) => {
    const sizeCLS = {
      big: DEFAULT_ERROR_CLS_BIG,
      medium: DEFAULT_ERROR_CLS_MEDIUM,
    };
    return sizeCLS[size];
  };

  errorCls = applyDefaultErrorCls
    ? getIconSuitableCls(
        twMerge(getInputErrorSizeCLS(inputSize), errorCls),
        icon,
        iconPosition
      )
    : errorCls;

  iconContainerCls = getCls(
    applyDefaultIconContainerCls,
    twMerge(
      (isSecInputIon
        ? DEFAULT_ICON_CONTAINER_CLS_SEC_INPUT
        : getInputIconSizeCLS(inputSize)) +
        (iconPosition === 'left' ? ' left-3' : ' right-3'),
      iconContainerCls
    ),
    iconContainerCls
  );

  return (
    <div className={containerCls}>
      {label && (
        <label htmlFor={props.id} className={labelCls}>
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        {icon && <div className={iconContainerCls}>{icon}</div>}

        <input
          className={`outline-none ${
            hasError
              ? errorCls
              : getIconSuitableCls(
                  twMerge(getInputSizeCLS(inputSize), ''),
                  icon,
                  iconPosition
                )
          }`}
          type={inputType}
          {...refs}
          {...props}
        />

        {type === 'password' && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-3 focus:outline-none"
          >
            {isPasswordVisible ? (
              <span>
                <EyeOpen width={20} height={20} strokeWidth={2} />
              </span>
            ) : (
              <span>
                <EyeClose width={20} height={20} strokeWidth={2} />
              </span>
            )}
          </button>
        )}
      </div>
      {errorMsg && <p className={errorMsgCls}>{errorMsg}</p>}
    </div>
  );
};

export default Input;
