import { v4 as uuidv4 } from 'uuid';

import i18n from '@/i18n';
import { SaasService } from '@/services';
import { APP_VERSION } from '@/utils/constants';

import { appToast } from '../ToastContainers';

function parseJwt(token: string) {
  if (!token) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const defaultAPIRequestMiddleware = (config: any) => {
  if (config.url.startsWith('https://storage.googleapis.com/')) return config;
  const accessToken = localStorage.getItem('access-token');
  const browserFingerprint = localStorage.getItem('browser-fingerprint');

  if (config.headers) {
    if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
    config.headers['X-B3-Traceid'] = uuidv4();
    config.headers['X-App-Version'] = APP_VERSION;
    config.headers['X-Caller-Name'] = 'DESKTOP';
    config.headers['X-Device-FP'] = browserFingerprint;
  }

  return config;
};

const errorMsgs: Record<number, string> = {
  // 500: 'Server error. Please try again later.',
  // 503: 'Service Unavailable. Please try again later.',
  401: 'Unauthorized',
  // 403: "You don't have permissions to access this resource.",
  // 504: 'Timeout error',
};
const errorCodes = Object.keys(errorMsgs);

let refreshTokenPromise: Promise<any> | null = null;

const defaultAPIResponseMiddleware = async (err: any, axiosAPI: any) => {
  // HANDLE GLOBAL 500, 503, 401 AND 403 STATUS CODE ERRORS
  const { status } = err.response || {};

  // HANDLE REFRESH TOKENS
  const originalConfig = err.config;

  if (
    !originalConfig.url.endsWith('/auth/refresh') &&
    err.response &&
    err.response.status === 401 &&
    //err.response.data.message === 'Revoked, expired or invalid token' &&
    !originalConfig._retry
  ) {
    if (!refreshTokenPromise) {
      const jwtPayload = parseJwt(localStorage.getItem('access-token') || '');
      if (!jwtPayload || !jwtPayload['TID']) {
        window.location.href = '/auth/sign-in';
        return Promise.reject(err);
      }
      refreshTokenPromise = refreshToken(jwtPayload['TID']);
    }

    try {
      originalConfig._retry = true;
      const res = await refreshTokenPromise;

      originalConfig.headers['Authorization'] =
        'Bearer ' + res.data.accessToken;
      localStorage.setItem('access-token', res.data.accessToken);
      return axiosAPI.request(originalConfig);
    } catch (err2) {
      if (errorCodes.includes(String(status)))
        appToast.error(i18n.t(errorMsgs[status]));
      return Promise.reject(err2);
    } finally {
      refreshTokenPromise = null;
    }
  }

  if (!refreshTokenPromise && errorCodes.includes(String(status)))
    appToast.error(i18n.t(errorMsgs[status]));

  return Promise.reject(err);
};

async function refreshToken(companyId: string) {
  try {
    const res = await SaasService.refreshAccessToken(companyId);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

export { defaultAPIRequestMiddleware, defaultAPIResponseMiddleware };
