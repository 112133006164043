import { useEffect, useRef, useState } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import moment from 'moment';
import 'moment/dist/locale/fr';
import { useTranslation } from 'react-i18next';

import i18n from '@/i18n';

import { useAppSelector } from './store-hooks';

export const getInitialTheme = () => {
  // ! Keep it for later
  // const browserTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
  //   ? 'dark'
  //   : 'light';

  const browserTheme = 'light';

  // ! Keep it for later
  // const localStorageTheme = localStorage.getItem('theme');

  const newTheme = browserTheme;

  applyTheme(newTheme);
  return newTheme;
};

const applyTheme = (theme: string) => {
  localStorage.setItem('theme', theme);
  document.documentElement.setAttribute('data-theme', theme);
  document.documentElement.classList.remove('light', 'dark');
  document.documentElement.classList.add(theme);
};

export const useAppTheme = () => {
  const [theme, setTheme] = useState(getInitialTheme());

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    applyTheme(newTheme);
  };

  return { theme, toggleTheme };
};

export const useAppTranslation = () => {
  const translationValues = useTranslation();

  return {
    ...translationValues,
    t: (key: string, options?: any) => translationValues.t(key, options) as any,
  };
};

export const useAppLanguage = () => {
  const [lang, setLang] = useState(i18n.language);

  const changeLang = (languageCode: string) => {
    setLang(languageCode);
    moment.locale(languageCode);
    i18n.changeLanguage(languageCode);
  };

  return { lang, changeLang };
};

export const useAppComponentVisible = (initialIsVisible: boolean) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event: any) =>
    ref.current &&
    !(ref.current as any).contains(event.target) &&
    setIsVisible(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return { ref, isVisible, setIsVisible };
};

export const useBrowserFingerprint = () => {
  const [isDoneFingerprinting, setIsDoneFingerprinting] = useState(false);
  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => {
        fp.get()
          .then(({ visitorId }) => {
            localStorage.setItem('browser-fingerprint', visitorId);
            setIsDoneFingerprinting(true);
          })
          .catch(() => {
            /* We're returning true in the then block and using catch on the Promise
        to ensure that the subsequent call (Promise) is executed, even if the fingerprint
        is not obtained successfully.*/
            setIsDoneFingerprinting(true);
          });
      })
      .catch(() => setIsDoneFingerprinting(true));
  }, []);
  return isDoneFingerprinting;
};

export const useAppFiscalYear = () => {
  const fiscalYear: number = useAppSelector((store) => {
    const fy = store.global.fiscalYear;
    return typeof fy === 'number' ? fy : parseInt(fy);
  });

  return fiscalYear;
};

const useSize = () => {
  const [windowSize, setWindowSize] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  });

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
    };
    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  }, []);

  return windowSize;
};

export default useSize;
