import React, { useEffect, useState } from 'react';

import {
  Add,
  Delete,
  Edit,
  MoreHoriz,
  Receipt,
  Visibility,
} from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { Modal, ModalGateway } from 'react-images';
import { useNavigate } from 'react-router-dom';

import { empty } from '@/assets/images/dashboard';
import {
  Badge,
  Button,
  ConfirmationModal,
  LoadingSpinner,
} from '@/components/global';
import {
  useAppComponentVisible,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { operationsErrors } from '@/lib/errors';
import type { Document, FiscalYear } from '@/services';
import BillingService from '@/services/BillingService';
import { formatBalance } from '@/utils/funcs';

import { ObjectPdf } from '../../common';
import { Tabs } from '../common';

enum StatusEnum {
  ALL = 'ALL',
  INIT = 'INIT',
  ARCHIVED = 'ARCHIVED',
}

enum QuotesStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum PreviewStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum DeleteQuoteStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}
enum ArchiveQuoteStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}
const PAGE_SIZE = 10;

const Quotes = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const [previewStatus, setPreviewStatus] = useState(PreviewStatus.INITIAL);
  const [deleteQuoteStatus, setDeleteQuoteStatus] = useState(
    DeleteQuoteStatus.INITIAL
  );
  const [archiveQuoteStatus, setArchiveQuoteStatus] = useState(
    ArchiveQuoteStatus.INITIAL
  );
  const [statusFilter, setStatusFilter] = useState(StatusEnum.INIT);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previewPdf, setPreviewPdf] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string>('');
  const [isPreviewModal, setIsPreviewModal] = useState<boolean>(false);
  const [isDeleteQuoteModal, setIsDeleteQuoteModal] = useState<boolean>(false);
  const [isArchiveQuoteModal, setIsArchiveQuoteModal] =
    useState<boolean>(false);
  const [documentLatestNote, setDocumentLatestNote] = useState<string>('');

  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);

  const [quotes, setQuotes] = useState<Document[]>([]);
  const [quotesStatus, setQuotesStatus] = useState(QuotesStatus.INITIAL);

  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const company = useAppSelector((store) => store.global.company);
  const [fiscalYearUsed, setFiscalYearUsed] = useState<FiscalYear>();

  const [filters, setFilters] = useState({
    fiscalYear: fiscalYear,
    page: 0,
    pageSize: PAGE_SIZE,
    status: StatusEnum.INIT.toString(),
  });

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  const setPageNumberFunction = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  useEffect(() => {
    const controller = new AbortController();

    setQuotesStatus(QuotesStatus.IN_PROGRESS);

    BillingService.getAllQuotes(filters, controller.signal)
      .then(({ data }) => {
        setQuotes(data.quotes);
        setTotalPages(data.totalNumberOfPages);
        setQuotesStatus(QuotesStatus.SUCCESS);
        setDocumentLatestNote(data.quotes[0]?.footerInfo ?? '');
      })
      .catch(() => {
        setQuotesStatus(QuotesStatus.ERROR);
      });

    return () => controller.abort();
  }, [filters.status]);

  useEffect(() => {
    setFilters({
      ...filters,
      fiscalYear,
      page: pageNumber,
      pageSize: PAGE_SIZE,
    });
    const fiscalYears: FiscalYear[] = JSON.parse(
      localStorage.getItem('fiscalYears') ?? ''
    );
    if (fiscalYears && fiscalYear) {
      setFiscalYearUsed(
        fiscalYears.find((year) => year.fiscalYear === Number(fiscalYear))
      );
    }
  }, [fiscalYear, pageNumber, PAGE_SIZE]);

  useEffect(() => {
    const controller = new AbortController();

    setQuotesStatus(QuotesStatus.IN_PROGRESS);

    BillingService.getAllQuotes(filters, controller.signal)
      .then(({ data }) => {
        setQuotes(data.quotes);
        setQuotesStatus(QuotesStatus.SUCCESS);
        setDocumentLatestNote(data.quotes[0]?.footerInfo ?? '');
      })
      .catch(() => {
        setQuotesStatus(QuotesStatus.ERROR);
      });

    return () => controller.abort();
  }, [filters.fiscalYear, filters.page, filters.pageSize]);

  const update = () => {
    setStatusFilter(StatusEnum.INIT);
    setPageNumber(0);
    setTotalPages(1);
  };

  const getPreview = (data: Document) => {
    setPreviewStatus(PreviewStatus.IN_PROGRESS);

    BillingService.getFilePreview(data, 'quote')
      .then(({ data }) => {
        setPreviewStatus(PreviewStatus.SUCCESS);
        const pdfURL = URL.createObjectURL(data);
        setPreviewPdf(pdfURL);
      })
      .catch(() => {
        setPreviewStatus(PreviewStatus.ERROR);
        appToast.error('Something went wrong, please try again.');
      });
  };

  const deleteQuote = () => {
    setDeleteQuoteStatus(DeleteQuoteStatus.IN_PROGRESS);

    BillingService.deleteQuote(selectedId)
      .then(() => {
        let newQuotesList = quotes;

        newQuotesList.forEach((el, idx) => {
          el.id === selectedId && newQuotesList.splice(idx, 1);
        });

        setQuotes(newQuotesList);
        setDeleteQuoteStatus(DeleteQuoteStatus.SUCCESS);
        handleDeleteQuoteModal();
      })
      .catch((e) => {
        e.response.data.code === operationsErrors.FiscalYear.YEAR_IS_LOCKED
          ? appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED))
          : appToast.error('Something went wrong, please try again.');
        setDeleteQuoteStatus(DeleteQuoteStatus.ERROR);
      });
  };

  const archiveQuote = () => {
    setArchiveQuoteStatus(ArchiveQuoteStatus.IN_PROGRESS);

    BillingService.archiveQuote(selectedId)
      .then(() => {
        let newQuotesList = quotes;

        newQuotesList.forEach((el, idx) => {
          el.id === selectedId && newQuotesList.splice(idx, 1);
        });

        setQuotes(newQuotesList);
        setArchiveQuoteStatus(ArchiveQuoteStatus.SUCCESS);
        handleArchiveQuoteModal();
      })
      .catch((e) => {
        e.response.data.code === operationsErrors.FiscalYear.YEAR_IS_LOCKED
          ? appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED))
          : appToast.error('Something went wrong, please try again.');
        setArchiveQuoteStatus(ArchiveQuoteStatus.ERROR);
      });
  };

  const transformQuoteIntoOtherDocument = (
    document: Document,
    type: string
  ) => {
    BillingService.createNewDocument(document, type)
      .then(() => {
        appToast.success(t('The delivery note has been transformed into bill'));
        update();
      })
      .catch(() => {
        appToast.error(t('Something went wrong, please try again.'));
      });
  };

  const duplicate = (document: Document) => {
    BillingService.createNewDocument(document, 'quote')
      .then((response) => {
        appToast.success(t('The quote has been duplicated'));

        let newQuotesList = quotes;
        newQuotesList.forEach((el, idx) => {
          //add data returned from promise just after the element
          el.id === document.id &&
            newQuotesList.splice(idx + 1, 0, response.data);
        });
        setQuotes(newQuotesList);

        update();
      })
      .catch(() => {
        appToast.error(t('Something went wrong, please try again.'));
      });
  };

  const handlePreviewModal = (data: Document) => {
    const newQuote = data;
    newQuote.companyName = company.name;
    newQuote.companyAddress = company.address;
    newQuote.companyTaxId = company.taxId;
    newQuote.companyLogoUrl = company.logoUrl;
    newQuote.documentValidated = false;
    getPreview(newQuote);
    setIsPreviewModal(!isPreviewModal);
  };

  const handleApplyStatusFilter = (status: StatusEnum) => {
    setStatusFilter(status);
    setFilters({ ...filters, status: status === StatusEnum.ALL ? '' : status });
  };

  const handleDeleteQuoteModal = () =>
    setIsDeleteQuoteModal(!isDeleteQuoteModal);

  const handleArchiveQuoteModal = () =>
    setIsArchiveQuoteModal(!isArchiveQuoteModal);

  const menuItems = [
    {
      type: 'item',
      label: t('Preview'),
      icon: <Visibility fontSize="inherit" htmlColor="#797e8a" />,
      onClick: (el: Document) => {
        handlePreviewModal(el);
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Edit'),
      icon: <Edit fontSize="inherit" htmlColor="#797e8a" />,
      onClick: (el: Document) => {
        navigate(`update/template/${el?.id}`, {
          state: { documentType: 'quote' },
        });
        update();
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Review'),
      icon: <Receipt fontSize="inherit" htmlColor="#797e8a" />,
      onClick: (el: Document) => navigate(`${el.id}/review`),
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Delete'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        setSelectedId(el.id ?? '');
        handleDeleteQuoteModal();
      },
      disabled: fiscalYearUsed && fiscalYearUsed.locked,
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Archive'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        setSelectedId(el.id ?? '');
        handleArchiveQuoteModal();
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Transform into delivery note'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        transformQuoteIntoOtherDocument(el, 'delivery-note');
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('transform into bill'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        transformQuoteIntoOtherDocument(el, 'invoice');
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Duplicate'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        duplicate(el);
      },
    },
  ];

  return (
    <section className="p-6 flex flex-col gap-[14px] border-1 border-solid rounded-md bg-red w-full h-screen overflow-y-scroll bg-white">
      <Tabs selected="Quotes" />

      <ConfirmationModal
        isOpen={isDeleteQuoteModal}
        action={deleteQuote}
        onClose={handleDeleteQuoteModal}
        actionName={t('Delete')}
        sensitivity="Destructive"
        title={t('Delete Quote')}
        message={t('you are about to delete this quote, are you sure?')}
      />
      <ConfirmationModal
        isOpen={isArchiveQuoteModal}
        action={archiveQuote}
        onClose={handleArchiveQuoteModal}
        actionName={t('Archive')}
        sensitivity="Primary"
        title={t('Archive Quote')}
        message={t('you are about to archive this quote, are you sure?')}
      />

      {/* @ts-expect-error */}
      <ModalGateway>
        {isPreviewModal && previewPdf ? (
          <Modal
            onClose={() => setIsPreviewModal(!isPreviewModal)}
            allowFullscreen={false}
          >
            <div>
              <ObjectPdf
                clsContainer="flex flex-col"
                url={previewPdf}
                cls={'w-[150vh] h-[90vh]'}
              />
            </div>
          </Modal>
        ) : null}
      </ModalGateway>

      <div className="py-3 pb-6 rounded-3xl bg-first">
        <div className="flex items-center justify-between px-3 pb-6 flex-wrap gap-6 w-full">
          <div className="flex items-center gap-3">
            <div className="flex bg-sec text-[#8D98AF] items-center gap-1 p-1 rounded-2xl">
              <button
                onClick={() => handleApplyStatusFilter(StatusEnum.ALL)}
                className={
                  statusFilter === StatusEnum.ALL
                    ? 'text-sm cursor-default p-2 text-[#171725] bg-[#F8FAFB] !drop-shadow-[0px_2px_4px_rgba(125,125,125,0.15)]  border-[1px] rounded-xl'
                    : `text-sm p-2 cursor-pointer hover:opacity-80 transition-opacity duration-20 cursor-pointer`
                }
              >
                {t('All')}
              </button>
              <button
                onClick={() => handleApplyStatusFilter(StatusEnum.INIT)}
                className={
                  statusFilter === StatusEnum.INIT
                    ? 'text-sm cursor-default p-2 text-[#171725] bg-[#F8FAFB] !drop-shadow-[0px_2px_4px_rgba(125,125,125,0.15)]  border-[1px] rounded-xl'
                    : `text-sm p-2 cursor-pointer hover:opacity-80 transition-opacity duration-20 cursor-pointer`
                }
              >
                {t('Unarchived')}
              </button>
              <button
                onClick={() => handleApplyStatusFilter(StatusEnum.ARCHIVED)}
                className={
                  statusFilter === StatusEnum.ARCHIVED
                    ? 'text-sm cursor-default p-2 text-[#171725] bg-[#F8FAFB] !drop-shadow-[0px_2px_4px_rgba(125,125,125,0.15)]  border-[1px] rounded-xl'
                    : `text-sm p-2 cursor-pointer hover:opacity-80 transition-opacity duration-20 cursor-pointer`
                }
              >
                {t('Archived')}
              </button>
            </div>
          </div>

          <Button
            size="small"
            label={t('Create new quote')}
            icon={<Add htmlColor="white" />}
            iconPosition="left"
            disabled={fiscalYearUsed && fiscalYearUsed.locked}
            onClick={() => {
              navigate('create/template', {
                state: { documentLatestNote, documentType: 'quote' },
              });
            }}
          />
        </div>

        {!quotes?.length ? (
          <div className="rounded-3xl bg-white p-3 flex flex-col items-center mt-20 text-center gap-5  text-gray-500">
            <img className="h-52" src={empty} alt="empty" />
            <h2 className="font-bold">{t('There is no quote')}</h2>

            <p className="max-w-[450px] text-sm ">
              {t('You can create a new quote by clicking on the button above')}
            </p>
          </div>
        ) : (
          <table className="w-full text-left text-gray-500 relative">
            {quotesStatus === QuotesStatus.IN_PROGRESS && (
              <div className="w-full h-full absolute bg-white opacity-50 cursor-no-drop z-10 flex items-center justify-center">
                <LoadingSpinner
                  width="35"
                  strokeWidth="4"
                  strokeColor="#5d5ff8"
                />
              </div>
            )}

            <thead className="text-gray-700 border-b">
              <tr>
                {HEADERS_ITEMS.map((name, idx) => (
                  <th
                    scope="col"
                    className="px-6 py-3 !text-xs !font-bold text-[#8D98AF]"
                    key={idx}
                  >
                    {t(name)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {quotes.length ? (
                quotes.map((el, idx) => (
                  <tr
                    onClick={() => {
                      navigate(`update/template/${el?.id}`, {
                        state: { documentType: 'quote' },
                      });
                      update();
                    }}
                    key={el.id}
                    className={`border-bottom font-bold px-3 cursor-pointer text-xs ${
                      idx % 2 ? 'bg-white' : 'bg-slate-50'
                    }`}
                  >
                    <td className="px-6 py-1 color-black truncate">
                      <Badge
                        label={t(el?.status ?? '')}
                        sensitivity={
                          el.status === 'Unarchived' ? 'positive' : 'warning'
                        }
                      />
                      {/* {translateStatus(el?.status ?? '')} */}
                    </td>

                    <th scope="row" className="px-6 py-1 color-black truncate ">
                      {el.documentNumber || '-'}
                    </th>
                    <th scope="row" className="px-6 py-1 color-black truncate ">
                      {moment(el?.date).format('LL')}
                    </th>

                    <td className="px-6 py-4 color-black truncate">
                      {el?.clientName}
                    </td>

                    <td className="px-6 py-1 color-black truncate">
                      <div className="truncate">
                        {formatBalance(el?.totalTTC, el?.currency)}
                        <p className="text-[10px] text-gray-400">
                          {t('VAT')}:{' '}
                          {formatBalance(el?.totalVAT, el?.currency)}
                        </p>
                      </div>
                    </td>

                    <td className="px-6 py-1 color-black truncate w-[10px]">
                      <div className="flex gap-2 justify-end">
                        <button
                          onClick={(e) => {
                            e.cancelable = true;
                            e.stopPropagation();
                            handleToggleMenu();
                            setSelectedId(el?.id ?? '');
                          }}
                        >
                          <MoreHoriz htmlColor="#656469" />
                        </button>

                        {isMenuOpen && selectedId === el.id && (
                          <div
                            className="flex flex-col min-w-[100px] bg-first absolute right-5 rounded-xl drop-shadow-2xl overflow-hidden mt-8 z-10"
                            ref={menuRef}
                          >
                            {menuItems.map((item, idx) =>
                              item.type === 'divider' ? (
                                <hr key={idx} />
                              ) : (
                                <button
                                  disabled={
                                    previewStatus ===
                                      PreviewStatus.IN_PROGRESS ||
                                    deleteQuoteStatus ===
                                      DeleteQuoteStatus.IN_PROGRESS ||
                                    archiveQuoteStatus ===
                                      ArchiveQuoteStatus.IN_PROGRESS ||
                                    item?.disabled
                                  }
                                  className={`flex cursor-pointer items-center text-[${
                                    item.label === t('Delete')
                                      ? '#d9534f'
                                      : '#797e8a'
                                  }] gap-2 hover:bg-[#eff3fd] px-3 py-2 duration-300 whitespace-nowrap disabled:opacity-50 disabled:cursor-auto`}
                                  key={idx}
                                  onClick={(e) => {
                                    e.cancelable = true;
                                    e.stopPropagation();
                                    item.onClick && item.onClick(el);
                                  }}
                                >
                                  <p>{item.label}</p>
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="relative w-full items-center justify-center">
                  <td className="absolute w-full h-full flex flex-col items-center py-4">
                    <img className="max-w-[250px]" src={empty} alt="empty" />
                    <div className="text-center w-full h-full flex flex-col gap-2">
                      <span className="color-gray text-[25px] font-bold">
                        {t('There is no quote')}
                      </span>

                      <p>
                        {t(
                          'You can add a new quote by clicking on the button above'
                        )}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {/* PAGINATION */}
        <div className="w-full pt-6 px-6 flex justify-end">
          <Pagination
            page={pageNumber}
            count={totalPages}
            onChange={setPageNumberFunction}
            variant="outlined"
            shape="rounded"
            color="primary"
            size="medium"
          />
        </div>
      </div>
    </section>
  );
};

const HEADERS_ITEMS = ['Status', 'Number', 'Date', 'Client', 'Amount', ''];

export default Quotes;
