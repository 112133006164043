import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from './store-hooks';

const SOCKET_URL =
  window.ENV[process.env.NODE_ENV || 'development'].wsNotificationUrl;

export const useWebSocket = (
  customParam?: string
): [WebSocket | null, boolean, (callback: (message: any) => void) => void] => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const [listeners, setListeners] = useState<((message: any) => void)[]>([]);

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const webSocketUrl = useMemo(() => {
    if (customParam == 'CHAT' && connectedUser?.companyId) {
      return `${SOCKET_URL}/${connectedUser.companyId}/mention/${connectedUser.id}`;
    } else if (connectedUser?.id && connectedUser?.companyId) {
      return `${SOCKET_URL}/${connectedUser.id}/${connectedUser.companyId}`;
    } else {
      return null;
    }
  }, [connectedUser, customParam]);

  useEffect(() => {
    if (webSocketUrl) {
      const socket = new WebSocket(webSocketUrl);
      setSocket(socket);

      socket.onopen = () => {
        setConnected(true);
      };

      socket.onclose = () => {
        setConnected(false);
      };

      socket.onmessage = (event) => {
        if (event.data === 'pong') {
          return; // Handle pong messages internally.
        }

        listeners.forEach((listener) => listener(event));
      };

      const interval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send('ping');
        }
      }, 30000); // Every 30 seconds

      return () => {
        clearInterval(interval);
        socket.close();
      };
    }
  }, [webSocketUrl, listeners]);

  const addMessageListener = (callback: (message: any) => void) => {
    setListeners((prev) => [...prev, callback]);
  };

  return [socket, connected, addMessageListener];
};
