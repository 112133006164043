import { type FC } from 'react';

import { type IconProps } from './IconType';

const Download: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6001 15.2198L3.6001 18.9257C3.6001 19.4873 3.82135 20.026 4.21517 20.4231C4.609 20.8202 5.14314 21.0434 5.7001 21.0434H18.3001C18.8571 21.0434 19.3912 20.8202 19.785 20.4231C20.1788 20.026 20.4001 19.4873 20.4001 18.9257V15.2198M12.0013 2.95663V14.9566M12.0013 14.9566L16.8013 10.3715M12.0013 14.9566L7.20127 10.3715"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Download;
