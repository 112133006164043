import { type FC } from 'react';

import { type IconProps } from './IconType';

const AlertTriangle: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 12.9708V8.13251M11.9999 16.5571V16.5996M18.0478 20.6292H5.95198C4.29975 20.6292 2.90542 19.525 2.46673 18.0143C2.27947 17.3694 2.50956 16.6977 2.86151 16.1257L8.90944 5.09779C10.3264 2.7952 13.6734 2.7952 15.0904 5.0978L21.1383 16.1257C21.4902 16.6977 21.7203 17.3694 21.5331 18.0143C21.0944 19.525 19.7001 20.6292 18.0478 20.6292Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertTriangle;
