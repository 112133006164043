import { type FC, useEffect, useState } from 'react';

import { Outlet } from 'react-router';

import { Navbar } from './Navbar';
import Sidebar from './Sidebar';

interface AccountingProps {}

const Accounting: FC<AccountingProps> = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 796) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    handleResize(); // Call on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white">
        {/* Navbar */}
        <Navbar />

        {/* Main section */}
        <main className="bg-white">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Accounting;
