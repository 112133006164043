import React from 'react';

import Image from '../Image';

interface ProfilePictureProps {
  name: string;
  profilePicUrl?: string; // Optional profile picture URL
  size?: number; // Optional size for the placeholder circle
  cls?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  name,
  profilePicUrl,
  cls = '',
  size = 40,
}) => {
  const generateInitials = (name: string): string => {
    const parts = name.split(' ');
    let initial = '';

    // Contact case (if there's a trailing empty part, e.g. "John Doe ")
    if (parts[parts.length - 1] === '') {
      initial = parts[0][0] + parts[parts.length - 2][0];
      if (parts.length === 2) {
        initial = parts[0][0];
      }
      return initial;
    }

    // User case (more than two parts)
    if (parts.length > 2) {
      initial = parts[0][0] + parts[parts.length - 1][0];
    } else if (parts.length > 1) {
      initial = parts[0][0] + parts[1][0];
    } else {
      initial = parts[0][0];
    }

    return initial.toUpperCase();
  };

  const initials = generateInitials(name);

  return (
    <div
      className={`flex items-center justify-center text-[#0756F2] font-semibold text-${
        size / 2
      } 
                  bg-[#E6EEFE] rounded-full uppercase`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {profilePicUrl ? (
        <Image
          alt="user avatar"
          url={profilePicUrl}
          cls={`${cls} min-w-full rounded-full object-cover`}
        />
      ) : (
        initials
      )}
    </div>
  );
};

export default ProfilePicture;
